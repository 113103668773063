
/**
 * TODO - Temp
 */
export const PARTNERS_DISPLAY_PRIORITY = [
  'itau',
  'itapeva',
  'ativossa',
  'ipanema',
  'nubank',
  'recovery',
  'credz',
  'credigy',
  'claro',
  'clarofatura',
  'bradesco',
  'bradescard',
  'itaucard',
  'mgw',
  'carrefour',
  'dacasa',
  'anhanguera',
  'cencosud',
  'uzecartoes',
  'btg',
  'arc',
  'ibi',
  'tribanco',
  'colombo',
  'havan',
  'liftcred',
  'trigg',
  'cielo',
  'credsystem',
  'lojasrenner',
  'renner',
  'riachuelo',
  'bancodobrasil',
  'hoepers',
  'neon',
  'net',
  'omni',
  'via',
  'sky',
  'vivo',
  'asp',
  'sophus',
  'portoseguro',
  'biva',
  'yduqs',
  'cea',
  'pernambucanas',
  'frd-fmu',
  'dscards',
  'rede',
  'bvfinanceira',
  'ibi-old',
  'dmcard',
  'santander',
  'brk',
  'marisa',
  'telefonica',
  'hipercard',
  'nextel',
  'credicard',
  'tim',
  'casasbahia',
  'leader',
  'bancovotorantim',
  'magazineluiza',
  'santanderfin',
  'losango',
  'avon',
  'natura',
  'boticario',
  'crefisa',
  'edp',
  'extra',
  'caixa',
  'mercadopago',
  'bmg',
  'original',
  'hsbc',
  'embratel',
  'cetelem',
  'banrisul',
  'gvt',
  'bancocacique',
  'fmu',
  'sorocred',
  'bvfin',
  'americanexpress',
  'cifra',
  'light',
  'comgas',
  'eletropaulo',
  'terra',
  'uol',
  'casavida',
  'bancointer',
  'sulamerica',
  'conectcar',
  'carsystem',
  'santinvest',
  'bancorcirenault',
  'bancorcinissan',
  'zema',
  'bv',
  'aegea',
  'queroquero',
  'bancopan',
  'oi',
  'netinterno',
  'c6bank',
];
