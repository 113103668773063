import { useLocation } from 'react-router-dom';

import { addCookie, getCookie } from '@libs/utils/helpers/getSafeCookies';
import { useRedline } from '@libs/redline';
import constants from '@common/constants';
import { jwtDecode } from 'jwt-decode';
import { base64Decrypt } from '@libs/utils/helpers/base64';

const useUserAuthentication = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const implicitAccessData = searchParams.get("a");
  const engageAccessData = searchParams.get("e");
	const utmMedium = searchParams.get("utm_medium");

  const { getCorrelationId } = useRedline();

  const setRLTracksAuthCookie = () => {
    const href = window?.location?.href;
    const referrer = href?.includes('login') || href?.includes('minhas-dividas') ? 'sign-in' : 'sign-up';
    const hasTokenAuth = getCookie(constants.legacyAuth.TOKEN);

		const engageOrigin = utmMedium && utmMedium === "sms" ? "sms-" : "email-";
		
		let isImplicitValidSession = false;
		if(implicitAccessData) {
			const decryptedImplicitAccessData = JSON.parse(base64Decrypt(implicitAccessData));
			const { access_token } = decryptedImplicitAccessData;

			const accessTokenExp = jwtDecode(access_token).exp || 0;
			const currentTimestamp = Math.floor(Date.now() / 1000);

			isImplicitValidSession = accessTokenExp > currentTimestamp;
		}
		
    const origin = isImplicitValidSession
      ? "implicit-"
      : engageAccessData ? engageOrigin : "";

    const authenticationType =
      (hasTokenAuth && !isImplicitValidSession)
        ? 'cached-sign-in'
        :  `${origin}${referrer}`;

    addCookieRLTracksAuth(authenticationType);
  };

  const addCookieRLTracksAuth = (authenticationType: string) => {

    const rLTracksAuthData = {
      authenticationType,
      correlationId: getCorrelationId(constants.redline.RL_TRACK_CID),
    };

    const oneHundredTwentyDays = new Date();
    oneHundredTwentyDays.setDate(oneHundredTwentyDays.getDate() + 120);

    addCookie({
      key: constants.redline.rl_tracks_auth,
      value: JSON.stringify(rLTracksAuthData),
      encrypt: true,
      expires: oneHundredTwentyDays,
    });
  };

  return {
    setRLTracksAuthCookie,
    addCookieRLTracksAuth,
  };
};

export default useUserAuthentication;
