import { useEffect, useState } from 'react';

// Services
import { Redline } from 'redline-client-sdk';

// Storage
import { usePushWebSettingsStore } from '../../storage/settings';

// Utils
import { subscribePushWeb } from '../../utils/subscribe';
import { shouldPromptAgain } from '../../utils/shouldPromptAgain';

export const useWidgetPrompt = () => {
  const { settings: configurationJaiminho } = usePushWebSettingsStore();
  const permissionNotification = Notification.permission;

  const hidePrompt =
    !configurationJaiminho.options.prompt ||
    configurationJaiminho.isSubscribed ||
    !shouldPromptAgain(configurationJaiminho.options.promptFrequency);

  const [isOpenPrompt, setIsOpenPrompt] = useState(false);

  useEffect(() => {
    if (!configurationJaiminho.isInitialized) {
      return setIsOpenPrompt(false);
    }

    setIsOpenPrompt(!hidePrompt);
  }, [configurationJaiminho.isInitialized, hidePrompt]);

  const handleClosePrompt = () => setIsOpenPrompt(false);

  const handleSubscribePrompt = async () => {
    await subscribePushWeb();

    let newWindow = window as Window &
      typeof globalThis & { cpRedline: Redline };

    if (!newWindow.cpRedline) return;
    await newWindow.cpRedline.track('userTracking.ElementClicked.v1', {
      name: 'web-push',
      location: 'dashboard-meu-cpf',
      elementType: 'button',
    });

    setIsOpenPrompt(false);
  };

  const handleDismissPrompt = () => {
    window.localStorage.setItem('pushPromptDismissedAt', String(Date.now()));
    setIsOpenPrompt(false);
  };

  return {
    isOpenPrompt,
    alertTitle:
      permissionNotification !== 'granted'
        ? 'Não esqueça de ativar a notificação.'
        : '',
    setIsOpenPrompt,
    handleClosePrompt,
    handleSubscribePrompt,
    handleDismissPrompt,
  };
};
