import constants from '@common/constants';
import useAuth from '@common/hooks/useAuth';
import useTrackOffer from '@common/hooks/useTrackOffer'; // TODO: renomear esse hook para um nome genério pq esta sendo usado em todo projeto
import { pageNames } from '@common/routes/pageNames';
import { useUserStore } from '@common/store/useUserStore';
import { addCookie } from '@libs/utils/helpers/getSafeCookies';
import { MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutPushWeb } from '@libs/jaiminho';
import usePushWebExperiment from '@common/abtest/experiments/usePushWebExperiment';

type UsePrivatePagesHandlersProps = {
  setShowLogoutModal: (prev: boolean) => void;
};

export const usePrivatePagesHandlers = ({
  setShowLogoutModal,
}: UsePrivatePagesHandlersProps) => {
  const { trackElementClicked } = useTrackOffer();
  const { resultIsJaiminho } = usePushWebExperiment();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserStore((state) => state.user);
  const { logOut } = useAuth();

  const onClickBackMenu = () => {
    const currentPath = location.pathname;
    if (currentPath.includes('acordo-fechado'))
      return navigate(pageNames.myDebts.path);

    return navigate(-1);
  };

  function onClickLogout(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    trackElementClicked({
      name: 'sair',
      location: 'ac-logout-modal',
      elementType: 'button',
    });

    if (resultIsJaiminho) logoutPushWeb();
    logOut();
    navigate(pageNames.signin.path);
  }

  function onClickNotLogout(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    trackElementClicked({
      name: 'sair-e-continuar-logado',
      location: 'ac-logout-modal',
      elementType: 'button',
    });

    const oneHundredTwentyDays = new Date();
    oneHundredTwentyDays.setDate(oneHundredTwentyDays.getDate() + 120);
    addCookie({
      key: constants.auth.KEEP_SESSION,
      value: JSON.stringify(user),
      encrypt: true,
      expires: oneHundredTwentyDays,
    });
    const isDev = import.meta.env.VITE_ENV === 'dev';
    const url = `https://${isDev ? 'dev.' : ''}acordocerto.com.br/`;
    window.open(url, '_self');
  }

  const onClickLogoutModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowLogoutModal(true);
  };

  return {
    onClickBackMenu,
    onClickLogout,
    onClickNotLogout,
    onClickLogoutModal,
  };
};
