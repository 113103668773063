import { usePushWebSettingsStore } from '../storage/settings';

export const getApplicationServerKey = () => {
  try {
    const configurationJaiminho = usePushWebSettingsStore;

    if (
      !configurationJaiminho.getState().settings.applicationServerKey ||
      !configurationJaiminho.getState().settings.applicationServerKey?.length
    ) {
      throw new Error('Push Web: Server key is not invalid!');
    }

    return configurationJaiminho.getState().settings.applicationServerKey;

    // const keyRaw = await fetch(
    //   configurationJaiminho.getState().settings.apiPath +
    //     '/push/publicSigningKeyBase64',
    //   {
    //     headers: {
    //       'content-type': 'application/json',
    //       'x-api-key': configurationJaiminho.getState().settings.apiKey,
    //     },
    //   }
    // );
    // const keyText = await keyRaw.text();
    // const appKey = convertBase64ToUnitInt(keyText);

    // configurationJaiminho.getState().handleSetSettings({
    //   applicationServerKey: appKey,
    // });

    // return appKey;
  } catch (error) {
    console.error(`Get application key: ${error}`);

    return error;
  }
};
