import useApiOnboarding from '@admission/apis/onboarding/useApiOnboarding';
import PageLoader from '@common/components/PageLoader';
import constants from '@common/constants';
import { useAtendeAuth } from '@common/hooks/useAtendeAuth';
import useAuth from '@common/hooks/useAuth';
import useGTM from '@common/hooks/useGTM';
import { pageNames } from '@common/routes/pageNames';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { useRedline } from '@libs/redline';
import { base64Decrypt } from '@libs/utils/helpers/base64';
import { delay } from '@libs/utils/helpers/delay';
import { getParamsUrl } from '@libs/utils/helpers/getParamsUrl';
import { isValidCpf } from '@libs/utils/validators/cpf';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { removeCookie, getCookie } from '@libs/utils/helpers/getSafeCookies';
import { unmask } from 'remask';

import '../styles.scss';

export default function PublicRoutes() {
  useAtendeAuth();
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth();
  const { search } = useLocation();
  const { createCorrelationId } = useRedline();

  const searchParams = new URLSearchParams(search);
  const implicitAccessData = searchParams.get('a');

  const navigate = useNavigate();
  const GTM = useGTM();
  const { apiOnboardingFindCustomer } = useApiOnboarding();

  async function isRedirectToPrivatePages() {
    if (isAuthenticated) {
      GTM.trigger.loginWithSuccess();

      await delay(500);
      const redirect =
        window.location.search && !implicitAccessData
          ? `${pageNames.myDebts.path + window.location.search}`
          : `${pageNames.myDebts.path}`;

      navigate(redirect, { replace: true });
    }
  }

  async function checkCurrentDocument() {
    const urlParams = getParamsUrl(window.location.search);
    const hash = urlParams['c'];

    if (!isAuthenticated && hash) {
      const decodedDocument = decodeURIComponent(hash);

      try {
        const cpfClean = unmask(base64Decrypt(decodedDocument));
        const validatedCpf = isValidCpf.safeParse(cpfClean);

        if (validatedCpf.success) {
          await apiOnboardingFindCustomer.send(cpfClean).then((res: any) => {
            if (res.data.registrado) {
              navigate(pageNames.signin.path + window.location.search, {
                state: base64Decrypt(decodedDocument),
              });
            } else {
              navigate(pageNames.signup.path + window.location.search, {
                state: base64Decrypt(decodedDocument),
              });
            }
          });
        }
      } catch (error) {
        console.error('CPF invalid', error);
      }
    } else {
      setLoading(false);
    }

    await delay(400);
    setLoading(false);
  }

  function onStart() {
    const isUtmMedium = searchParams.get('utm_medium');
    const hasSessionStarted = getCookie('rl_sscompleted');
    if (isUtmMedium === 'email' && hasSessionStarted) {
      removeCookie('rl_sscompleted');
    }
  }

  useEffect(() => {
    checkCurrentDocument();
  }, []);

  useEffect(() => {
    isRedirectToPrivatePages();
  }, [isAuthenticated]);

  useEffect(() => {
    createCorrelationId(constants.redline.RL_TRACK_CID);
  }, []);

  useEffect(() => {
    onStart();
  }, []);

  return (
    <Conditional
      condition={loading}
      renderIf={<PageLoader />}
      renderElse={<Outlet />}
    />
  );
}
