import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const usePushWebExperiment = () => {
  const testCookieName = 'dx-push-web';

  const VARIANTS = {
    A: 'with-hermes',
    B: 'with-jaiminho',
  };

  const currentResult = getCookie(testCookieName, false);

  return {
    resultIsJaiminho: !!currentResult?.includes(VARIANTS.B),
  };
};

export default usePushWebExperiment;
