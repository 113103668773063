import untilLoad from '@libs/utils/helpers/untilLoad';
import { setUserId, usePushWebSettingsStore } from '@libs/jaiminho';
import usePushWebExperiment from '@common/abtest/experiments/usePushWebExperiment';

const usePushNotification = () => {
  const { isInitialized } = usePushWebSettingsStore((store) => store.settings);
  const { resultIsJaiminho } = usePushWebExperiment();

  const track = async (user: any) => {
    const maxTimeoutToLoadScripts = 6000;

    if (resultIsJaiminho) {
      const jaiminhoServiceLoaded = await untilLoad(
        () => isInitialized,
        maxTimeoutToLoadScripts,
        1000
      );

      if (jaiminhoServiceLoaded) {
        // await pushOn(5000);
        setUserId(user?.documento);
      }
    } else {
      const hermesServiceLoaded = await untilLoad(
        () => window['hermesService' as any]?.isInitialized,
        maxTimeoutToLoadScripts
      );

      if (hermesServiceLoaded) {
        const hermes = window['hermesService' as any];
        hermes.pushOn(0);
        hermes.setUserId(user?.documento);
      }
    }
  };

  return {
    track,
  };
};

export default usePushNotification;
