import { useEffect, useState } from 'react';
import { FormattedDebt } from '../types';
import { PARTNERS_DISPLAY_PRIORITY } from '../constants';
import useDebtsFixedOrderingExperiment from '@common/abtest/experiments/useDebtsFixedOrderingExperiment';

type UseDebtsOrderingProps = {
  debtsList: FormattedDebt[];
};

export default function useDebtsOrdering({ debtsList }: UseDebtsOrderingProps) {
  /**
   * Temp pausing the ordering experiment
   * const { resultIsWithOrdering } = useDebtsFixedOrderingExperiment();
   */
  const resultIsWithOrdering = true
  const [previousDebtsList, setPreviousDebtList] = useState(debtsList);
  const [orderedDebts, setOrderedDebts] = useState<FormattedDebt[]>([]);
  const [isLoadingNewDebtsInLastPos, setIsLoadingNewDebtsInLastPos] =
    useState<boolean>();

  useEffect(() => {
    const orderedDebts = ordenateDebts(debtsList, previousDebtsList);

    setOrderedDebts(orderedDebts);
    setPreviousDebtList(debtsList);
  }, [debtsList, isLoadingNewDebtsInLastPos]);

  function ordenateDebts(
    newDebtsResponse: FormattedDebt[],
    currentDebts: FormattedDebt[]
  ): FormattedDebt[] {
    if (!resultIsWithOrdering) return newDebtsResponse;

    const sortedByPartnerPriority = sortDebtsByPartner(newDebtsResponse);
    if (!isLoadingNewDebtsInLastPos) return sortedByPartnerPriority;
    return withNewerDebtsInListEnd(sortedByPartnerPriority, currentDebts);
  }

  function loadNewDebtsInRegularPos() {
    setIsLoadingNewDebtsInLastPos(false);
  }

  function loadNewDebtsInLastPos() {
    setIsLoadingNewDebtsInLastPos(true);
  }

  return {
    loadNewDebtsInRegularPos,
    loadNewDebtsInLastPos,
    orderedDebts,
  };
}

function sortDebtsByPartner(debts: FormattedDebt[]): FormattedDebt[] {
  const safeDebts = debts || [];
  return safeDebts.sort((debtA, debtB) => {
    const AIdentifier = debtA.partner.partnerIdentifier;
    const BIdentifier = debtB.partner.partnerIdentifier;
    const APosition = PARTNERS_DISPLAY_PRIORITY.indexOf(AIdentifier);
    const BPosition = PARTNERS_DISPLAY_PRIORITY.indexOf(BIdentifier);
    const aHasPriority = APosition !== -1;
    const bHasPriority = BPosition !== -1;

    if (!aHasPriority && !bHasPriority) return 0;
    else if (!aHasPriority) return 1;
    else if (!bHasPriority) return -1;
    else return APosition - BPosition;
  });
}

function withNewerDebtsInListEnd(
  debtsList: FormattedDebt[],
  previousDebtsList: FormattedDebt[]
) {
  const safeDebtsList = debtsList || [];
  const safePreviousList = previousDebtsList || [];
  const allDebtsAreNew = safePreviousList.length === 0;

  if (allDebtsAreNew) return safeDebtsList;

  const newDebts = safeDebtsList.filter((loadedDebt) => {
    return !safePreviousList.some((debt) => debt.id === loadedDebt.id);
  });

  return safeDebtsList.sort((debtA, debtB) => {
    const aIsNew = newDebts.some((debt) => debt.id == debtA.id);
    const bIsNew = newDebts.some((debt) => debt.id == debtB.id);
    if (aIsNew) return 1;
    if (bIsNew) return -1;
    return 0;
  });
}
