export const initialState = {
  settings: {
    applicationServerKey: undefined,
    options: {
      pushOnTimeout: 15000,
      prompt: true,
      promptFrequency: '3 days',
      promptTitle:
        'Boas-vindas! <strong style:"font-size: 1em; font-family: verdana, sans-serif; color: #000000;">Quer receber nossos avisos sobre novas oferas?</strong>',
      promptMessage:
        'Ative as notificações para receber as últimas notícias e atualizações.',
      promptButton: 'Sim, me envie as novidades',
      promptDismiss: 'Não, valeu',
      promptButtonColor: '#065FD4',
      promptPosition: 'right',
      bell: true,
      bellSize: '30px',
      bellBackgroundImage: '',
      bellBackgroundColor: '#065FD4',
      bellPosition: 'right',
      button: true,
      buttonContainer: 'div#push-subscribe',
      buttonSubscribe: 'Receber notificações',
      buttonSubscribed: 'Incrito',
      buttonUnsubscribe: true,
      buttonPadding: '1em',
      buttonFontSize: 'medium',
      buttonColor: '#FFF',
      buttonBackgroundColor: '#065FD4',
      buttonSubscribedColor: '#666',
      buttonSubscribedBackgroundColor: '#EEE',
      deniedTitle: 'As notificações estão bloqueadas',
      deniedMessage:
        'Abra as preferências do navegador ou clique no cadeado 🔒 próximo à barra de endereço para alterar suas preferências de notificação.',
      margin: '1rem',
      fontFamily: 'verdana, sans-serif',
      welcome: false,
      welcomeTitle: '',
      welcomeBody: '',
      welcomeIcon: '',
      welcomeBadge: '',
    },
    apiKey: '',
    apiPath: '',
    isInitialized: false,
    isSubscribed: false,
    userId: null,
    marketplaceApi: null,
  },
};
