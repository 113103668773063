// Hooks
import { usePushWebSettingsStore } from '../storage/settings';

declare const window: {
  pwRegistration: ServiceWorkerRegistration;
} & Window;

export const unsubscribePushWeb = async () => {
  try {
    const configurationJaiminho = usePushWebSettingsStore.getState();

    const swRegistration = window.pwRegistration;

    if (!swRegistration) {
      return console.error(
        'Push Web: SwRegistration no window was not found or is not registered.'
      );
    }

    const subscription = await swRegistration.pushManager.getSubscription();

    if (!subscription)
      return console.error('Push Web: Subscription not founded.');

    const hasUnsubscribe = await subscription.unsubscribe();

    if (!hasUnsubscribe)
      return console.error('Push Web: Unsubscription failed');

    await fetch(configurationJaiminho.settings.apiPath + '/push/unsubscribe', {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'content-type': 'application/json',
        'x-api-key': configurationJaiminho.settings.apiKey,
      },
    });

    configurationJaiminho.handleSetSettings({
      isSubscribed: false,
    });

    console.log(
      `Push Web: Is subscribed ${configurationJaiminho.settings.isSubscribed}`
    );
  } catch (error) {
    console.error(`Push Web: Unsubscribed: ${error}`);
  }
};
